import { FunctionComponent } from 'react';

// Components
import Head from 'next/head';
import Page from '@Components/Utils/Page';
import { DataManager } from '@Components/Utils/DataTransfer';

// Utilities
import dynamic from 'next/dynamic';
import { PageView } from '@Components/Utils/Lazy/Script';
import ComponentLazy from '@Components/Utils/Lazy/Component';

// Types
import HomePage from '@Pages/home/HomePage';
import { GetServerSideProps, InferGetServerSidePropsType } from 'next';

// APIs
import homePageApi from '@Libs/api/cms/homePage';
import { getServerSideHttp } from '@Libs/http/cms';

// Contexts
import { useViewDetected } from '@Components/Utils/ViewDetected';

// const AppInfo = dynamic(() => import('@Pages/home/Components/AppInfo'), { ssr: false, loading: () => <div style={{ height: '750px' }} /> });
// const HomeBanner = dynamic(() => import('@Pages/home/Components/Banner'), { ssr: false, loading: () => <div style={{ height: '400px' }} /> });
// const Featured = dynamic(() => import('@Pages/home/Components/Featured'), { ssr: false, loading: () => <div style={{ height: '180px' }} /> });
// const FastSearch = dynamic(() => import('@Pages/home/Components/FastSearch'), { ssr: false, loading: () => <div style={{ height: '164px' }} /> });

// const Posts = dynamic(() => import('@Pages/home/Components/Posts'), { ssr: false });
// const HomeFAQ = dynamic(() => import('@Pages/home/Components/FAQ'), { ssr: false });
// const HomeMedia = dynamic(() => import('@Pages/home/Components/Media'), { ssr: false });
// const Clinics = dynamic(() => import('@Pages/home/Components/Clinics'), { ssr: false });
// const HomeVideo = dynamic(() => import('@Pages/home/Components/Video'), { ssr: false });
// const Services = dynamic(() => import('@Pages/home/Components/Services'), { ssr: false });
// const Questions = dynamic(() => import('@Pages/home/Components/Questions'), { ssr: false });
// const HomeCustomer = dynamic(() => import('@Pages/home/Components/Customer'), { ssr: false });
// const HomeEpharmacy = dynamic(() => import('@Pages/home/Components/Epharmacy'), { ssr: false });
// const HomeHealthCheck = dynamic(() => import('@Pages/home/Components/HealthCheck'), { ssr: false });

export interface HomeProps extends HomePage {}

const Home: FunctionComponent<InferGetServerSidePropsType<typeof getServerSideProps> & HomeProps> = (props) => {
    const { deviceType } = useViewDetected();

    const HomeBanner = dynamic(() => import('@Pages/home/Components/Banner'), {
        ssr: false,
        loading: () => <div style={{ height: deviceType === 'desktop' ? '400px' : '250px' }} />,
    });

    const {
        faq,
        video,
        posts,
        banner,
        search,
        clinics,
        featured,
        services,
        epharmacy,
        // questions,
        hotServices,
        organization,
        productCircle,
        communication,
        newsfeeds,
    } = props;


    return (
        <DataManager {...props}>
            <Head>
                {/* LOAD IMAGE FOR LCP */}
                <link rel={'preload'} as={'image'} href={'/assets/downloadApp/app_store_white.png'} />
                <link rel={'preload'} as={'image'} href={'/assets/downloadApp/google_play_white.png'} />
                {banner?.[deviceType]?.url && <link rel={'preload'} as={'image'} href={banner?.[deviceType]?.url} />}

                <meta property="al:ios:app_store_id" content="1041327503" />
                <meta property="al:ios:app_name" content="eDoctor - Know Your Healt‪h‬" />

                <meta property="al:android:package" content="vn.edoctor.userapp" />
                <meta property="al:android:app_name" content="eDoctor - Know Your Healt‪h" />
            </Head>

            <Page>
                {banner && <HomeBanner />}

                {search && (
                    <ComponentLazy
                        dynamic={() => import('@Pages/home/Components/FastSearch')}
                        placeholder={() => <div style={{ height: deviceType === 'desktop' ? '168px' : '260px' }} />}
                    />
                )}
                {featured && (
                    <ComponentLazy
                        dynamic={() => import('@Pages/home/Components/Featured')}
                        placeholder={() => <div style={{ height: '180px' }} />}
                    />
                )}
                {productCircle && (
                    <ComponentLazy
                        dynamic={() => import('@Pages/home/Components/AppInfo')}
                        placeholder={() => <div style={{ height: '750px' }} />}
                    />
                )}
                {hotServices && (
                    <ComponentLazy dynamic={() => import('@Pages/home/Components/Services')} rootMargin={'100px 0px'} />
                )}
                {epharmacy && (
                    <ComponentLazy
                        dynamic={() => import('@Pages/home/Components/Epharmacy')}
                        rootMargin={'100px 0px'}
                    />
                )}
                {organization && (
                    <ComponentLazy dynamic={() => import('@Pages/home/Components/Customer')} rootMargin={'100px 0px'} />
                )}
                {services && (
                    <ComponentLazy
                        dynamic={() => import('@Pages/home/Components/HealthCheck')}
                        rootMargin={'100px 0px'}
                    />
                )}
                {clinics && (
                    <ComponentLazy dynamic={() => import('@Pages/home/Components/Clinics')} rootMargin={'100px 0px'} />
                )}
                {video && (
                    <ComponentLazy dynamic={() => import('@Pages/home/Components/Video')} rootMargin={'100px 0px'} />
                )}
                {/* {questions && newsfeeds && (
                    <ComponentLazy
                        dynamic={() => import('@Pages/home/Components/Questions')}
                        rootMargin={'100px 0px'}
                    />
                )} */}
                {newsfeeds && (
                    <ComponentLazy
                        dynamic={() => import('@Pages/home/Components/Newsfeeds')}
                        rootMargin={'100px 0px'}
                    />
                )}
                {posts && (
                    <ComponentLazy dynamic={() => import('@Pages/home/Components/Posts')} rootMargin={'100px 0px'} />
                )}
                {faq && <ComponentLazy dynamic={() => import('@Pages/home/Components/FAQ')} rootMargin={'100px 0px'} />}
                {communication && (
                    <ComponentLazy dynamic={() => import('@Pages/home/Components/Media')} rootMargin={'100px 0px'} />
                )}

                {/* <FastSearch />
                <Featured />
                <AppInfo />
                <Services />
                <HomeEpharmacy />
                <HomeCustomer />
                <HomeHealthCheck />
                <Clinics />
                <HomeVideo />
                <Questions />
                <Posts />
                <HomeFAQ />
                <HomeMedia /> */}
            </Page>

            <PageView />
        </DataManager>
    );
};

export default Home;

type HomePageData = {
    key: string;
    [key: string]: any;
}[];

export const getServerSideProps: GetServerSideProps = async (ctx) => {
    const http = getServerSideHttp(ctx);

    const { data: homePage } = await homePageApi(http).get();

    const data: HomePageData = homePage.data || [];

    const props = data.reduce((obj, item) => {
        obj[item.key] = { ...item };
        return obj;
    }, {});

    return {
        props,
    };
};
