import React, { FunctionComponent, useEffect, useState, useRef, RefObject } from 'react';

// Utilities
import nextDynamic, { DynamicOptions, Loader } from 'next/dynamic';

interface ComponentLazyProps {
    threshold?: number;
    rootMargin?: string;
    dynamicOptions?: DynamicOptions;
    dynamic?: DynamicOptions | Loader;
    placeholder?: ({ error, isLoading, pastDelay }: { error?: Error | null; isLoading?: boolean; pastDelay?: boolean; retry?: () => void; timedOut?: boolean }) => JSX.Element | null;
    [key: string]: any;
}

const ComponentLazy: FunctionComponent<ComponentLazyProps> = ({ placeholder, threshold, rootMargin, dynamic, dynamicOptions, ...props }) => {
    const divRef: RefObject<HTMLImageElement> = useRef(null);
    const [isIntersecting, setIntersecting] = useState<boolean>(false);

    useEffect(() => {
        let unmouted = false;
        let observer: IntersectionObserver;

        const handleLoadComponent = () => {
            if (divRef) {
                try {
                    observer = new IntersectionObserver(
                        (entries: IntersectionObserverEntry[]) => {
                            entries.forEach((entry) => {
                                if (!unmouted && entry.isIntersecting) {
                                    observer.unobserve(divRef.current);
                                    setIntersecting(true);
                                }
                            });
                        },
                        {
                            threshold,
                            rootMargin,
                        },
                    );

                    observer.observe(divRef.current);
                } catch (error) {
                    setIntersecting(true);
                }
            }
        };

        if (document.readyState !== 'complete') {
            window.addEventListener('load', handleLoadComponent);
        } else {
            handleLoadComponent();
        }

        return () => {
            unmouted = true;
            window.removeEventListener('load', handleLoadComponent);
        };
    }, [divRef]);

    const defaultOptions = {
        ...dynamicOptions,
        loading: placeholder,
        ssr: false,
    };

    return (
        <>
            {!isIntersecting ? (
                <div ref={divRef}>{placeholder({ isLoading: true })}</div>
            ) : (
                (() => {
                    const Component: any = nextDynamic(dynamic, defaultOptions);

                    return <Component {...props} />;
                })()
            )}
        </>
    );
};

ComponentLazy.defaultProps = {
    fadeIn: true,
    threshold: 0,
    placeholder: () => null,
    rootMargin: '50px 0px 50px 0px',
};

export default ComponentLazy;
